<template>
  <div class="md:w-1/2 sm:w-full mx-auto">
    <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
      <div class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
        <div class="text-center">
          <h1
            class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl"
          >
            <span class="block">{{ message }}.</span>
          
          </h1>
           <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            Thank you for purchasing your Personalised GraduationClip. You will receive further information by email after your ceremony.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  props: { message: String}
};
</script>
