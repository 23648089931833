var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:w-1/2 sm:w-full mx-auto"},[_vm._m(0),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"space-y-4 text-gray-700",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"flex flex-wrap -mx-2 space-y-4 md:space-y-0"},[_c('div',{staticClass:"w-full px-2"},[_c('ValidationProvider',{attrs:{"rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('label',{staticClass:"block mb-1",attrs:{"for":"formGridCode_name"}},[_vm._v("Full name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline",class:{
                'border-red-700 text-red-900': errors.length > 0,
                'border-green-700': valid,
              },attrs:{"type":"text","id":"formGridCode_name","name":"name","placeholder":"Your Name to be shown at graduation"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex flex-wrap -mx-2 space-y-4 md:space-y-0"},[_c('div',{staticClass:"w-full px-2"},[_c('ValidationProvider',{attrs:{"name":"Email Address","rules":"required|email|personal_email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function({ valid, errors }){return [_c('label',{staticClass:"block mb-1",attrs:{"for":"formGridCode_email"}},[_vm._v("Email Address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],ref:"email",staticClass:"w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline",class:{
                'border-red-700 text-red-900': errors.length > 0,
                'border-green-700': valid,
              },attrs:{"type":"email","id":"formGridCode_email","name":"email","placeholder":"john.doe@example.com"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"flex flex-wrap -mx-2 space-y-4 md:space-y-0"},[_c('div',{staticClass:"w-full px-2"},[_c('ValidationProvider',{attrs:{"name":"Confirmation Email Address","rules":"required|confirmed:email"},scopedSlots:_vm._u([{key:"default",fn:function({ valid, errors }){return [_c('label',{staticClass:"block mb-1",attrs:{"for":"formGridCode_email_conf"}},[_vm._v("Confirm Email Address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirmEmail),expression:"form.confirmEmail"}],staticClass:"w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline",class:{
                'border-red-700 text-red-900': errors.length > 0,
                'border-green-700': valid,
              },attrs:{"type":"email","id":"formGridCode_email_conf","name":"confirmed_email","placeholder":"john.doe@example.com"},domProps:{"value":(_vm.form.confirmEmail)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "confirmEmail", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"flex flex-wrap -mx-2 space-y-4 md:space-y-0"},[_c('div',{staticClass:"w-full px-2"},[_c('label',{staticClass:"block mb-1",attrs:{"for":"formGridCode_event"}},[_vm._v("Event")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.event),expression:"form.event"}],staticClass:"w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline",attrs:{"id":"formGridCode_event","name":"event"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "event", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Select your event date")]),_vm._l((_vm.events),function(ceremony){return _c('option',{key:ceremony['id'],domProps:{"value":ceremony['id']}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(ceremony["datetime"]))+" ")])})],2)])]),_c('button',{staticClass:"inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",attrs:{"type":"submit"}},[_vm._v(" Go to Checkout ")])])]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-wrap -mx-2 space-y-4 md:space-y-0"},[_c('div',{staticClass:"mt-8 mx-auto max-w-7xl px-4 sm:mt-4 sm:px-6"},[_c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"text-2xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-2xl"},[_c('span',{staticClass:"block text-mygrad"},[_vm._v("Let's get started")])])])])])
}]

export { render, staticRenderFns }