import axios from "axios";
import router from "../router.js";

export default {
    async startSessionForTenant(tenant) {
        const r = await axios
            .post(
                `/session`,
                { tenant: tenant }
            );
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + r.data.token;
        localStorage.setItem("token", r.data.token);
    },
    async setTokensForLoggedIn(tokens) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + tokens.token;
        localStorage.setItem("token", tokens.token);
    },

    // authentication status
    authenticated: false,

    // Send a request to the login URL and save the returned JWT
    login(creds, redirect) {
        return axios.post('/login', creds).then((response) => response.data).then( (tokens) => {
            localStorage.setItem('token',tokens.tokens.token)

            this.authenticated = true
            axios.defaults.headers.common["Authorization"] =
            "Bearer " + tokens.tokens.token;
            // Redirect to a specified route
            if (redirect) {
                router.push({path: "/admin/upload"})
            }

        })
    },

    // To log out
    logout: function () {
        localStorage.removeItem('token');
        this.authenticated = false;
        router.push('/admin/login')
    }
}
