<template>
  <div class="md:w-1/2 sm:w-full mx-auto">
    <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0" v-if="!successful" >
      <div class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
        <div class="text-center">
          <h1
            class="
              text-4xl
              tracking-tight
              font-extrabold
              text-gray-900
              sm:text-5xl
              md:text-6xl
            "
          >
            <span class="block">We are just making sure all is okay.</span>
            <span class="block text-mygrad">Shouldn't be long now....</span>
          </h1>
        </div>
      </div>
    </div>
    <automatic-submission-accepted v-if="successful" />
  </div>
</template>

<script>
import AutomaticSubmissionAccepted from "./AutomaticSubmissionAccepted.vue";
export default {
  name: "AutomaticSubmissionAccept",
  props: {},
  data() {
    return {
      successful: false,
    };
  },
  beforeCreate() {
    var reg_id = this.$route.params.id;
    this.$router.push(`/thumbnails/${this.$route.query.uni}/${reg_id}`);
  },
  components: {
    AutomaticSubmissionAccepted
  }
};
</script>