const organisers = {
  kings: {
    name: "Kings College London",
    id: "S2luZ3MgQ29sbGVnZSBMb25kb24="
  },
  lsbu: {
    name: "London South Bank University",
    id: "TG9uZG9uIFNvdXRoIEJhbmsgVW5pdmVyc2l0eQ=="
  }
};

const apiURL = "https://sbmokw4jca.execute-api.eu-west-2.amazonaws.com/prod/";

export default (apiURL, organisers);
