<template>
  <div>
    <title-page
      title="Your Graduation Made Easy"
      v-if="state == 'title'"
      @submit="state = 'one'"
      :organiserName="organiser.name"
    />
    <RegistrationForm
      v-if="state == 'one'"
      :events="events"
      @submit="submitStepOne"
      :additionalFieldsInput="additionalFieldsInput"
      :organiserSlug="organiser.slug"
    />
    <finished
      v-if="state == 'finished'"
      :name="firstname(personalDetails.name)"
      :registrationID="this.registrationID"
    />
  </div>
</template>

<script>
import RegistrationForm from "./Registration/RegistrationForm.vue";
import Finished from "./Registration/Finished.vue";
import axios from "axios";
import TitlePage from "./TitlePage.vue";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import {endSession, trackEvent} from "../Analytics.vue"

export default {
  name: "Form",
  props: {
    organiser: Object,
    additionalFieldsInput: Array,
  },
  components: {
    TitlePage,
    RegistrationForm,
    Finished,
  },
  data: function () {
    return {
      state: "title",
      personalDetails: {},
      id: null,
      isLoading: false,
      events: [],
      registrationID: ""
    };
  },
  mounted() {
    this.events = this.organiser["ceremonies"];
  },
  methods: {
    firstname(name) {
      return name.split(" ")[0];
    },
    submitStepOne(formData) {
      this.personalDetails = formData;
      this.personalDetails.organisation = this.organiser.name;
      console.log(formData);

      var mappedAdditionalFields = {}
      formData["additionalFields"].forEach((item) => {
        mappedAdditionalFields[item["name"]] = item["value"];
      });

      axios
        .post(
          "/registrations",
          {
            name: formData["name"],
            email: formData["email"],
            ceremony: formData["event"],
            additionalFields: mappedAdditionalFields,
          }
        )
        .then((r) => {
          console.log(r.data);
          this.registrationID = r.data.id;
          trackEvent(this.$route.path, {label: "REGISTRATION_SUBMITTED", registration_id: this.registrationID, type: "FREE"})
          const page_mode = this.$route.query.mode;
          if (r.data.ceremony_ready && page_mode != "live") {
            this.$router.push(`/thumbnails/${this.$route.params.uni}/${r.data.id}`)
          }
        })
        .then(() => {
          this.state = "finished";
          endSession();
        });
    },
    submit() {
      return true;
    },
  },
};
</script>
