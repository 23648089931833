<template>
  <div>
    <marston-warning v-if="state == 'warning'" :organiserName="organiser.name"/>
    <individual-university-purchase v-else-if="state == 'sale'" @submit="orderButtonClicked" :organiserName="organiser.name" :organiserSlug="organiser.slug"/>
    <Step1
      v-if="state == 'one'"
      :events="events"
      @submit="submitStepOne"
      :additionalFieldsInput="additionalFieldsInput"
    />
    <finished
      v-if="state == 'redirecting'"
      :name="firstname(personalDetails.name)"
      :url="url"
    />
  </div>
</template>

<script>
import Step1 from "./Step1.vue";
import Finished from "./Finished.vue";
import axios from 'axios';
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import IndividualUniversityPurchase from './IndividualUniversityPurchase.vue';
import MarstonWarning from "../MarstonWarning.vue";
import {endSession, trackEvent} from "../../Analytics.vue";
export default {
  name: "Form",
  props: {
    organiser: Object,
    additionalFieldsInput: Array,
  },
  components: {
    Step1,
    Finished,
    IndividualUniversityPurchase,
    MarstonWarning
  },
  data: function () {
    return {
      state: "wait",
      personalDetails: {},
      id: null,
      isLoading: false,
      events: [],
      url: ""
    };
  },
  mounted() {
    if (this.organiser && this.organiser.tenant === "marston") {
      this.state = "warning";
      trackEvent(this.$route.path, {label: "MARSTON_WARNING_SHOWN"})
      return;
    }

    this.state = "sale";
    this.events = this.organiser["ceremonies"];
    if (this.$route.query.bundle == "True") {
      console.log("BUNDLE")
      this.state = "one";
    }
  },
  methods: {
    orderButtonClicked(){ 
      this.state = 'one';
      trackEvent(this.$route.path, {label: "ORDER_NOW_BUTTON_CLICK"})
    },
    firstname(name) {
      return name.split(" ")[0];
    },
    submitStepOne(formData) {
      this.personalDetails = formData;
      this.personalDetails.organisation = this.organiser.name;
      console.log(formData);

      var mappedAdditionalFields = {}
      formData["additionalFields"].forEach((item) => {
        mappedAdditionalFields[item["name"]] = item["value"];
      });
      this.state = "redirecting";
      
      axios
        .post(
          "/purchase",
          {
            name: formData["name"],
            email: formData["email"],
            ceremony: formData["event"],
            additionalFields: mappedAdditionalFields,
            bundle: this.$route.query.bundle == "True" ? true : false,
          }
        )
        .then((r) => {
          this.url = r.data;
          trackEvent(this.$route.path, {label: "PURCHASE_FORM_SUBMITTED", paid: false})
          endSession();
          window.location.href = r.data;
        });
    },
    submit() {
      return true;
    },
  },
};
</script>
