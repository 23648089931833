<template>
  <div class="md:w-1/2 sm:w-full mx-auto">
    <div v-if="completed" class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
      <div class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
        <div class="text-center">
          <h1
            class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl"
          >
            <span class="block">Upload Completed</span>
          </h1>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            You uploaded a source file for {{ selected_university["name"] }}
            {{
              ceremonies.filter((x) => x["id"] == selected_ceremony)[0][
                "datetime"
              ] | formatDate
            }}
          </p>
        </div>
      </div>
    </div>

    <form class="space-y-4 text-gray-700" @submit.prevent v-if="!completed">
      <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
        <div class="mt-8 mx-auto max-w-7xl px-4 sm:mt-4 sm:px-6">
          <div class="text-center">
            <h1
              class="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-2xl"
            >
              <span class="block text-mygrad">Upload Source File</span>
            </h1>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
        <div class="w-full px-2">
          <button
            v-if="!showAll"
            type="submit"
            @click="showAllCeremonies(true)"
            class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Show All Ceremonies (warning this will include ceremonies you have
            already uploaded)
          </button>
          <button
            v-else
            type="submit"
            @click="showAllCeremonies(false)"
            class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Hide already uploaded ceremonies (Normal mode)
          </button>
        </div>
      </div>
      <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
        <div class="w-full px-2">
          <h2 v-if="stage >= 0 && universities.length > 0">
            Select University
          </h2>
          <select
            class="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
            v-model="selected_university"
            @change="onUniChange"
            v-if="stage >= 0 && universities.length > 0"
          >
            <option disabled :value="null">Select University</option>
            <option
              v-for="(uni, c_index) in this.universities"
              :key="c_index"
              :value="uni"
            >
              {{ uni["name"] }}
            </option>
          </select>
        </div>
      </div>
      <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
        <div class="w-full px-2">
          <h2 v-if="stage >= 1">Now Select Ceremony</h2>
          <select
            v-if="stage >= 1"
            v-model="selected_ceremony"
            class="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
          >
            <option disabled :value="null">Select your event date</option>

            <option
              v-for="ceremony in ceremonies"
              :value="ceremony['id']"
              :key="ceremony['id']"
            >
              {{ ceremony["datetime"] | formatDate }}
            </option>
          </select>
        </div>
      </div>
      <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
        <div class="w-full px-2">
          <input
            class="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
            type="file"
            name="file"
            accept="video/mp4"
            @change="onFileChange"
            v-if="selected_ceremony != null"
          />
          <p class="mt-2 text-sm text-gray-500">
            Or paste a YouTube URL - only use one option
          </p>
          <input
            class="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
            type="text"
            name="url"
            placeholder="Enter URL"
            v-model="youtube_url"
            v-if="selected_ceremony != null"
            @change="onUrlChange"
          />
        </div>
      </div>

      <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
        <div class="w-full px-2">
          <video-player
            v-if="source_file || youtube_url"
            :options="videoOptions"
            ref="videoPlayer"
          ></video-player>
        </div>
      </div>

      <div
        class="flex flex-wrap -mx-2 space-y-4 md:space-y-0"
        v-if="source_file != null || youtube_url != null"
      >
        <div class="w-full px-2">
          <h3>
            Now please go to a reference frame for the first walk shot, press
            pause and then press Create First Reference
          </h3>
          <button
            type="submit"
            @click="createReference('wide')"
            class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Create First Reference
          </button>
        </div>
      </div>

      <div
        class="flex flex-wrap -mx-2 space-y-4 md:space-y-0"
        v-if="references.wide != null"
      >
        <div class="w-full px-2">
          <h3>
            Now please go to a reference frame for the SECOND walk shot, press
            pause and then press Create SECOND Reference
          </h3>
          <button
            type="submit"
            @click="createReference('close')"
            class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Create Second Reference
          </button>
        </div>
      </div>
      <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
        <div class="w-full px-2">
          <button
            type="submit"
            @click="submitForUpload"
            class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            v-if="references.wide != null && references.close != null"
            :disabled="isUploading"
          >
            Submit
          </button>
          <div
            class="w-full bg-gray-200 rounded-full dark:bg-gray-700"
            v-if="stage > 50 || stage == -1"
          >
            <div
              class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
              :style="'width: ' + progress + '%'"
            >
              {{ progress }}%
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>


<script>
import axios from "axios";
import "videojs-contrib-hls";

export default {
  name: "Upload",
  data: function () {
    return {
      youtube_url: null,
      stage: 0,
      isUploading: false,
      progress: false,
      universities: [],
      selected_university: null,
      ceremonies: [],
      selected_ceremony: null,
      source_file: null,
      references: {
        wide: null,
        close: null,
      },
      completed: false,
      showAll: false,
    };
  },
  computed: {
    videoOptions() {
      if (!this.source_file) {
        return {
          techOrder: ["youtube"],
          sources: [
            {
              type: "video/youtube",
              src: this.youtube_url,
            },
          ],
          aspectRation: "16:9",
          width: "100%",
          responsive: true,
          youtube: {
            ytControls: 2,
          },
        };
      }
      var URL = window.URL || window.webkitURL;
      var fileURL = URL.createObjectURL(this.source_file);

      return {
        sources: [
          {
            type: "video/mp4",
            src: fileURL,
          },
        ],
        aspectRation: "16:9",
        responsive: true,
      };
    },
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  beforeCreate() {
    axios
      .get(`/universities`)
      .then((response) => response.data)
      .then((response_data) => (this.universities = response_data))
      .catch(() => this.$router.push("/admin/login"));
  },
  mounted() {
    var showAll = this.$route.query.showAll;
    this.showAll = showAll == "true";
  },
  methods: {
    showAllCeremonies(e) {
      this.$router.push({ path: "/admin/upload", query: { showAll: e } });
      this.$router.go();
    },
    createReference(t) {
      if (t == "wide") {
        // TODO: tidy reference
        this.references.wide = this.player.currentTime();
      } else {
        this.references.close = this.player.currentTime();
      }
    },

    async getSignedUrl(ceremony_id, file_name, references, type = "FILE") {
      return axios
        .post(`/ceremonies/${ceremony_id}/upload`, {
          filename: file_name,
          references: references,
          type: type
        })
        .then((response) => response.data["url"]);
    },
    async submitForUpload() {
      if (this.youtube_url) {
        this.submitYoutubeFile();
      } else {
        this.submitSourceFile();
      }
    },
    async submitYoutubeFile() {
      this.stage = 100;
      this.isUploading = true;
      await this.getSignedUrl(
        this.selected_ceremony,
        this.youtube_url,
        this.references,
        "YOUTUBE"
      );
      this.completed = true;
    },
    async submitSourceFile() {
      this.stage = 100;
      this.isUploading = true;
      var signedUrl = await this.getSignedUrl(
        this.selected_ceremony,
        this.source_file.name,
        this.references
      );
      this.progress = 0;

      var formData = new FormData();
      for (const [key, value] of Object.entries(signedUrl.fields)) {
        formData.append(key, value);
      }
      var file = new File([this.source_file], this.source_file.name);
      formData.append("file", file);
      await axios
        .post(signedUrl.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"];
            return data;
          },
          onUploadProgress: (progressEvent) => {
            this.stage = -1;
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            // do whatever you like with the percentage complete
            // maybe dispatch an action that will update a progress bar or something
            this.progress = percentCompleted;
          },
        })
        .then(() => {
          this.completed = true;
        });
    },

    onUniChange() {
      this.getCeremonies(this.selected_university["id"]).then(
        (ceremonies) => (this.ceremonies = ceremonies)
      );
      this.stage = 1;
      this.selected_ceremony = null;
    },
    async getCeremonies(uni_id) {
      if (typeof this.showAll != "undefined" && this.showAll) {
        return axios
          .get(`/universities/${uni_id}/ceremonies`)
          .then((response) => response.data);
      } else {
        return axios
          .get(`/universities/${uni_id}/ceremonies?ready=false`)
          .then((response) => response.data);
      }
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      console.log(files[0]);
      this.source_file = files[0];
    },
    onUrlChange(e) {
      console.log(e);
    },
  },

  components: {},
};
</script>
<style scoped>
video {
  min-width: 100%;
  min-height: 40em;
  position: relative !important;
}
.video-js {
  width: 100% !important;
  min-height: 40em !important;
  position: relative !important;
}
</style>