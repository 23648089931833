<template>
  <div class="md:w-1/2 sm:w-full mx-auto">
    <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
      <div class="mt-8 mx-auto max-w-7xl px-4 sm:mt-4 sm:px-6">
        <div class="text-center">
          <h1
            class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl"
          >
            <span class="block">Privacy Policy</span>
          </h1>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            In accordance with of the Data Protection Act 2018 and the General
            Data Protection Regulation (GDPR) 2016 (implemented 2018), to comply
            with this new legislation, our privacy policy is to inform you how
            we look after your personal data and information.
          </p>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            <b>What information do we collect?</b>
            We collect information from you when
            you place an order or subscribe to our newsletter. When ordering or
            registering on our site, as appropriate, you may be asked to enter
            your: name, e-mail address, mailing address, phone number or credit
            card information. Credit card information will not be retained
            unless you choose to save card details. You also have the right to
            request that your personal data is deleted or to restrict processing
            in accordance with data protection laws. We will respond to and
            complete any data removal requests within 30 days. When sending a
            contact form message on our website, we ask for your name, e-mail
            and a message which will include any information you wish to give
            us. When purchasing a pre-order or products such as our
            MyGraduationClip, you will be asked to input timestamp data along
            with your name and e-mail address and any other relevant data after
            you have ordered the product. This is for us to identify where you
            are in the video and process the order.
          </p>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            <b>What do we use your information for? </b>Any of the information we
            collect from you may be used in one of the following ways: To
            process transactions Your information, whether public or private,
            will not be sold, exchanged, transferred, or given to any other
            company for any reason whatsoever, without your consent, other than
            for the express purpose of delivering the purchased product or
            service requested. To Answer Queries When you send a contact form
            message on our contact page or sending us an e-mail independently,
            we use the information you have provided to contact you back.
          </p>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            <b>How do we protect your information?</b> We implement a variety of
            security measures to maintain the safety of your personal
            information when you place an order or enter, submit, or access your
            personal information. We use a payment gateway to process you
            orders. All supplied sensitive/credit information is encrypted into
            our Payment gateway providers database only to be accessible by
            those authorized with special access rights to such systems, and are
            required to keep the information confidential. After a transaction,
            your private information (credit cards numbers, financials, etc.)
            will not be stored on our servers.
          </p>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            <b>Do we use cookies?</b> Yes, cookies are small files that a site or its
            service provider transfers to your computers hard drive through your
            Web browser (if you allow) that enables the sites or service
            providers systems to recognize your browser and capture and remember
            certain information. We use cookies to help us remember and process
            the items in your shopping cart. For more information about cookies,
            and how to disable cookies, visit http://www.allaboutcookies.org.
          </p>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            <b>Do we disclose any information to outside parties?</b> We do not sell,
            trade, or otherwise transfer to outside parties your personally
            identifiable information. This does not include trusted third
            parties who assist us in operating our website, conducting our
            business, or servicing you, so long as those parties agree to keep
            this information confidential. We may also release your information
            when we believe release is appropriate to comply with the law,
            enforce our site policies, or protect ours or others rights,
            property, or safety. However, non-personally identifiable visitor
            information may be provided to other parties for marketing,
            advertising, or other uses. When registering for a university funded
            ‘free personalised clip’, and provided a data sharing agreement with
            the university is in place, your data is released to the university
            along with your contact details and chosen GDPR preferences. We use
            FetchApp to deliver some of our products to customers, FetchApp are
            within GDPR Compliance:
            https://fetchapp.freshdesk.com/support/solutions/articles/11000052065-what-is-fetchapp-doing-about-gdpr-
          </p>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            <b>How long do we keep your data.</b> We retain client data for 5 years
            after contract completion. We retain all clips produced for a
            maximum period of 5 years.
          </p>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            <b>Third party links</b> We share your Personal Information with third
            parties to help us use your Personal Information, as described
            above. We may also share
            your Personal Information to comply with applicable laws and
            regulations, to respond to a subpoena, search warrant or other
            lawful request for information we receive, or to otherwise protect
            our rights.
          </p>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            <b>Do not track</b> Please note that we do not alter our Site’s data
            collection and use practices when we see a Do Not Track signal from
            your browser.
          </p>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            <b>Childrens Online Privacy Protection Act Compliance</b>

            We are in compliance with the requirements of COPPA (Childrens
            Online Privacy Protection Act), we do not collect any information
            from anyone under 13 years of age. Our website, products and
            services are all directed to people who are at least 13 years old or
            older.
          </p>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            <b>Online Privacy Policy Only</b> This online privacy policy applies only
            to information collected through our website and not to information
            collected offline.
          </p>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            <b>Terms and Conditions</b> Please also visit our Terms and Conditions
            section establishing the use, disclaimers, and limitations of
            liability governing the use of our website at
            http://www.mygraduationfilm.com/?page_id=1375
          </p>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            <b>Your Consent</b> By using our site, you consent to our web site privacy
            policy.
          </p>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            <b>Changes to our Privacy Policy</b> If we decide to change our privacy
            policy, we will post those changes on this page. This page was last
            updated on 23rd April 2020. We reserve the right to change and amend
            this policy from time to time.
          </p>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            <b>Your rights</b> You may have access to all information that we hold by
            requesting this in writing. Please either email
            info@mygraduationfilm.com or post a letter to: The Forge, Unit 7,
            Wrotham Business Park, Barnet, Hertfordshire EN5 4SZ, UK. You can
            ask the organisation to change incorrect or incomplete data at any
            time. You may ask us to delete or remove personal data that we hold
            unless there is no other legal reason for us to keep it.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "PrivacyPolicy",
  props: {},
};
</script>
  