import Vue from "vue";
import App from "./App.vue";
import Analytics from "./Analytics.vue"
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "@/assets/styles/index.css";
import moment from "moment";
import VueRouter from "vue-router";
import VueVideoPlayer from 'vue-video-player';
import marked from 'marked';

Vue.use(VueRouter);
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("dddd Do MMMM YYYY h:mma");
  }
});
Vue.filter("markdownToHtml", function(value){
  console.log(marked(value));
  return marked(value);
});
import AudioVisual from 'vue-audio-visual'
var videojs = require('video.js');
window.videojs = videojs;
import 'videojs-youtube';
if (typeof window !== 'undefined' && window.videojs) {
  window.videojs = videojs;
  require('videojs-youtube');
}
require('videojs-contrib-hls');
Vue.use(AudioVisual)
Vue.use(VueVideoPlayer)


Vue.use(Analytics)
import pluralize from "pluralize";
/**
 * Vue filter to round the decimal to the given place.
 * http://jsfiddle.net/bryan_k/3ova17y9/
 *
 * @param {String} value    The value string.
 * @param {Number} decimals The number of decimal places.
 */
 Vue.filter('round', function(value, decimals) {
  if(!value) {
    value = 0;
  }

  if(!decimals) {
    decimals = 0;
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value;
});
Vue.filter("pluralize", function(value, number) {
  return pluralize(value, number);
});
Vue.component("ValidationProvider", ValidationProvider);

Vue.component("ValidationObserver", ValidationObserver);
Vue.config.productionTip = false;

import router from "./router.js";
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
new Vue({
  router,
  render: h => h(App),
  components: {
    ValidationProvider
  }
}).$mount("#app");
