<template>
    <div class="md:w-1/2 sm:w-full mx-auto">
      <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
        <div class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6" v-if="this.errorMessage == null">
          <div class="text-center">
            <h1
              class="
                text-2xl
                tracking-tight
                font-extrabold
                text-gray-900
                sm:text-3xl
                md:text-4xl
              "
            >
              <span class="block" 
                >Your Digital Download is ready!</span
              ><br/>
              <span class="block text-mygrad"
                >Click the button below to download the film, and remember to store it somewhere safe!</span
              >
            </h1>
          </div>
        </div>
        <div class="mt-8 mx-auto py-3 max-w-7xl w-full" v-if="this.errorMessage == null">
          <div class="text-center w-full h-auto">
            <button
              @click="submit"
              type="submit"
              class="
                inline-flex
                items-center
                px-4
                py-2
                border border-gray-300
                shadow-sm
                text-lg
                font-medium
                rounded-md
                text-gray-700
                bg-white
                hover:bg-gray-50
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
              "
            >
              Download!
            </button>
          </div>
        </div>
        <div class="mt-8 mx-auto py-3 max-w-7xl w-full" v-else>
          <div class="text-center w-full h-auto">
            <h2
              class="
                text-2xl
                tracking-tight
                font-extrabold
                text-gray-900
                sm:text-3xl
                md:text-4xl
              "
            >
              <span class="block"
                >{{ errorMessage }}</span
              >
            </h2> 
          </div>
        </div>
         
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import {endSession, trackEvent} from "../../Analytics.vue";
  export default {
    name: "DigitalDownloadDelivery",
    props: {  },
    data : function() {
      return {
        errorMessage: null
      }
    },
    methods: {
      submit() {
        trackEvent(this.$route.path, {label: "DIGITAL_DOWNLOAD_COLLECT", digital_download_id: this.$route.params.id})

        axios({"method": "POST",
        "baseURL":  process.env.VUE_APP_DIGITAL_DOWNLOAD_BASE_URL,
        "url": "/download",
          "data": {
            digital_download_id: this.$route.params.id,
          }
        })
        .then((r) => {
          endSession();
          this.errorMessage = "Your download should start shortly - your browser will handle the rest!"
          window.location.href = r.data;
        })
        .catch((e) => {
           this.errorMessage = e.response.data.message;
        })
      },
    },
  };
  </script>