<template>
  <div>
    <SnapshotGallery
      name="Matthew Hoare"
      :images="galleryImages"
      @submit="gallerySelectionSubmitted"
      @notMe="notMeClick"
      v-if="state == 'gallery'"
    />
    <SnapshotDetailsForm
      v-if="state == 'one'"
      :events="events"
      @submit="submitStepOne"
    />
    <SnapshotImageSelector
      v-if="state == 'selector'"
      :name="personalDetails.name"
      :images="images"
      @submit="submitImageSelection"
    />
    <CeremonyNotReady v-if="state == 'error'" />
  </div>
</template>
  
  <script>
import SnapshotImageSelector from "./SnapshotImageSelector.vue";
// Import stylesheet
import axios from "axios";
import "vue-loading-overlay/dist/vue-loading.css";
import SnapshotDetailsForm from "./SnapshotDetailsForm.vue";
import SnapshotGallery from "./SnapshotGallery.vue";
import CeremonyNotReady from './CeremonyNotReady.vue';
export default {
  name: "Snapshot",
  props: {
    organiser: Object,
    additionalFieldsInput: Array,
  },
  components: {
    SnapshotImageSelector,
    SnapshotDetailsForm,
    SnapshotGallery,
    CeremonyNotReady
  },
  data: function () {
    return {
      state: "gallery",
      personalDetails: {},
      allImages: [],
      isLoading: false,
      events: [],
      registrationID: "",
      images: [],
      galleryImages: [],
      showingPreviousNext : false
    };
  },
  mounted() {
    this.events = this.organiser["ceremonies"];
  },
  created: function () {
    var sub = this.$route.query.registration;
    if (typeof sub == "undefined") {
      this.state = "one";
      return;
    }
    axios
      .get(`/registrations/${this.$route.query.registration}`)
      .then((r) => {
        this.registrationID = this.$route.query.registration;
        this.personalDetails.name = r.data.name;
        this.personalDetails.email = r.data.email;
        this.personalDetails.event = r.data.ceremony;
        this.populated = true;

        this.state = "selector";
      })
      .catch(() => {
        this.state = "one";
      }).then(() => {
        axios
        .post(
          `${process.env.VUE_APP_SNAPSHOT_API_BASE}/images`,
          {
            ceremony: this.personalDetails.event,
            name: this.personalDetails.name,
          }
        )
        .then((r) => {
          console.log(r.data);
          this.allImages = r.data;
          this.galleryImages = r.data.map((x) => {
            return { src: x["preview"] };
          });
          if (r.data.length > 1 && (r.data[0].score == r.data[1].score)) {
              this.state = "gallery"
          }
          else if (r.data.length == 1 || r.data[0].score > 0.75) {
            this.gallerySelectionSubmitted(0);
          } else {
            this.state = "gallery";
          }
        }).catch(() => {
            this.state = 'error'
        });
      });
  },
  methods: {
    firstname(name) {
      return name.split(" ")[0];
    },
    submitStepOne(formData) {
      this.personalDetails = formData;
      axios
        .post(
          `${process.env.VUE_APP_SNAPSHOT_API_BASE}/images`,
          {
            ceremony: this.personalDetails.event,
            name: this.personalDetails.name,
          }
        )
        .then((r) => {
          console.log(r.data);
          this.allImages = r.data;
          this.galleryImages = r.data.map((x) => {
            return { src: x["preview"] };
          });
          if (r.data.length > 1 && (r.data[0].score == r.data[1].score)) {
              this.state = "gallery"
          }
          else if (r.data.length == 1 || r.data[0].score > 0.75) {
            this.gallerySelectionSubmitted(0);
          } else {
            this.state = "gallery";
          }
        }).catch(() => {
            this.state = 'error'
        });
    },
    notMeClick() {
      console.log("Thats not me - showing previous and next");
      var newImages = []
      this.allImages.forEach(element => {
        if (element.previous != null) {
          newImages.push(element.previous)
        }
        if (element.next != null) {
          newImages.push(element.next)
        }
      });
      console.log(newImages)
      this.allImages = newImages
      this.showingPreviousNext = true;
      this.galleryImages = newImages.map(x => {
        return { src: x["preview"] };
      })
      console.log(this.galleryImages)
    },
    submitImageSelection(currentIndex) {
    axios
        .post(
          `${process.env.VUE_APP_SNAPSHOT_API_BASE}/orders`,
          {
            name: this.personalDetails["name"],
            email: this.personalDetails["email"],
            ceremony: this.personalDetails["event"],
            slug: this.$route.params.uni,
            tenant: "mygraduationfilm",
            file: this.images[currentIndex].src
          }
        )
        .then((r) => {
          window.location.href = r.data;
        });
    
    },
    gallerySelectionSubmitted(index) {
      this.images = this.allImages[index]["images"].map((x) => {
        return { src: x };
      });
      this.state = "selector";
    },
  },
};
</script>
  