<template>
  <div>
    <UpdateRequested v-if="state == 'requested'"></UpdateRequested>
    <RegistrationForm
      v-else-if="showForm"
      :events="events"
      @submit="updateRegistrationDetails"
      :additionalFieldsInput="this.additionalFieldsInput"
      :name="this.personalDetails.name"
      :email="this.personalDetails.email"
      :event="this.personalDetails.event"
    />
    <finished
      v-if="showFinished"
      :name="firstname(this.personalDetails.name)"
      :registrationID="this.registrationID"
    />
  </div>
</template>

<script>
import RegistrationForm from "./RegistrationForm.vue";
import UpdateRequested from "./UpdateRequested.vue"
import axios from "axios";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import Finished from "./Finished.vue";
import { endSession, trackEvent } from "../../Analytics.vue";

export default {
  name: "Update",
  props: {
    organiser: Object,
    additionalFieldsInput: Array,
  },
  components: {
    RegistrationForm,
    Finished,
    UpdateRequested,
  },
  data: function () {
    return {
      populated: false,
      personalDetails: {},
      id: null,
      isLoading: false,
      events: [],
      state: "loading"
    };
  },
  created: function () {
    if (this.$route.query.token) {
      // Code to execute if this.$route.query.token exists

      axios
        .get(
          `/registrations/${this.$route.params.id}?token=${this.$route.query.token}`
        )
        .then((r) => {
          this.registrationID = this.$route.params.id;
          this.personalDetails.name = r.data.name;
          this.personalDetails.email = r.data.email;
          this.personalDetails.event = r.data.ceremony;
          this.additionalFieldsInput.map((item) => {
            item["value"] = r.data.additionalFields[item.name];
            console.log(item);
          });
          this.populated = true;
          this.state = "ready";
        });
    }
    else {
      axios
        .post(
          `/registrations/${this.$route.params.id}/request-update`
        )
        .then(() => {
          this.state = "requested";
        });
    }
  },
  computed: {
    showForm() {
      return this.populated && this.state != "finished";
    },
    showFinished() {
      return this.state == "finished";
    },
  },
  mounted() {
    this.events = this.organiser["ceremonies"];
  },
  methods: {
    firstname(name) {
      return name.split(" ")[0];
    },
    updateRegistrationDetails(formData) {
      this.personalDetails = formData;
      this.personalDetails.organisation = this.organiser.name;
      console.log(formData);

      var mappedAdditionalFields = {};
      formData["additionalFields"].forEach((item) => {
        mappedAdditionalFields[item["name"]] = item["value"];
      });

      axios
        .put(`/registrations/${this.$route.params.id}?token=${this.$route.query.token}`, {
          name: formData["name"],
          email: formData["email"],
          ceremony: formData["event"],
          additionalFields: mappedAdditionalFields,
        })
        .then((r) => {
          console.log(r.data);
          trackEvent(this.$route.path, {
            label: "REGISTRATION_UPDATED",
            registration_id: this.registrationID,
          });
          if (r.data.ceremony_ready) {
            this.$router.push(
              `/thumbnails/${this.$route.params.uni}/${r.data.id}`
            );
          }
        })
        .then(() => {
          this.state = "finished";
          endSession();
        });
    },
    submit() {
      return true;
    },
  },
};
</script>
