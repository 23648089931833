<template>
  <div class="lg:w-3/4 md:w-5/6 sm:w-5/6 mx-auto">
    <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
      <div class="mt-4 mx-auto max-w-7xl px-4 sm:mt-2 sm:px-6">
        <div class="text-center">
          <h2
            class="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-2xl"
          >
            <span class="block">{{ name }}</span>
          </h2>
          <!-- Section 1: Highlighting the Moment -->
          <h2 class="text-xl font-bold text-gray-900 whitespace-nowrap">
            🎓 Your moment, your memory. 🎓
          </h2>

          <!-- Section 2: Snapshot Selection Prompt -->
          <p class="mt-2 text-gray-700">
            Choose the Snapshot that brings your achievement to life.
          </p>
        </div>
      </div>
      <div class="image-carousel-container">
        <!-- Large Image Preview 
      <div class="preview-container">
        <img :src="images[currentIndex].src" alt="" class="preview-image" style="max-width: 80%;"/>
      </div>
      -->
        <!-- Carousel for Thumbnails -->
        <vue-slick-carousel
          ref="largeCarousel"
          :asNavFor="$refs.smallCarousel"
          v-bind="settings"
          @beforeChange="syncSliders"
        >
          <template #prevArrow>
            <div class="custom-arrow">
              <img :src="require('@/assets/arrow_left.svg')" />
            </div>
          </template>
          <div
            v-for="(image, index) in images"
            :key="image.id"
            class="preview"
            @click="setCurrentIndex(index)"
          >
            <img :src="image.src" :alt="image.alt" />
          </div>
          <template #nextArrow>
            <div class="custom-arrow">
              <img :src="require('@/assets/arrow_right.svg')" />
            </div>
          </template>
        </vue-slick-carousel>
        <button class="btn" type="submit" @click="submit">Buy Now</button>
        <!--<VueSlickCarousel
      ref="smallCarousel"
      :asNavFor="$refs.largeCarousel"
      :slidesToShow="6"
      :focusOnSelect="true"
      :centerMode="true"
      @beforeChange="syncSliders"
    >
      <div
        v-for="(image, index) in images"
        :key="image.id"
        class="thumbnail"
        @click="setCurrentIndex(index)"
      >
        <img :src="image.src" :alt="image.alt" />
      </div>
    </VueSlickCarousel>-->
      </div>
    </div>
  </div>
</template>
  
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "SnapshotImageSelector",
  components: {
    VueSlickCarousel,
  },
  props: { name: String, images: Array },
  data() {
    return {
      currentIndex: 0,

      settings: {
        focusOnSelect: true,
        infinite: true,
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipe: true,
        draggable: true,
        dots: false,
        arrows: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              focusOnSelect: true,
              infinite: true,
              centerMode: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              swipe: true,
              draggable: true,
              dots: false,
              arrows: true,
            },
          },
        ],
      },
    };
  },
  methods: {
    submit() {
      this.$emit("submit", this.currentIndex);
    },
    setCurrentIndex(index) {
      this.currentIndex = index;
    },
    syncSliders(currentPosition, nextPosition) {
      console.log(nextPosition);
    },
  },
};
</script>
<style scoped>
.image-carousel-container {
  max-width: 85vw;
  width: 100%;
  margin: auto;
}
.slick-prev:before {
  content: "";
}
.slick-next:before {
  content: "";
}

.preview-container {
  text-align: center;
  margin-bottom: 20px;
}

.preview-image {
  height: auto;
  max-height: 80vh;
  border: 3px solid #ddd;
  border-radius: 8px;
}

.thumbnail img {
  max-height: 20vh;
  padding: 5px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.1s ease;
}

.thumbnail img:hover,
.thumbnail img:focus {
  opacity: 1;
}

.preview {
  max-width: 90vw;
}
.preview img {
  max-height: 70vh;
  margin-left: auto;
  margin-right: auto;
  display: block;

  padding: 5px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.1s ease;
}

.preview img:hover,
.preview img:focus {
  opacity: 1;
}
/* Style the button and place it in the middle of the container/image */
.image-carousel-container .btn {
  position: relative;
  /*top: 50%;*/
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #08697b;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.image-carousel-container .btn:hover {
  background-color: black;
}
</style>