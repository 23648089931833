<template>
  <div class="md:w-1/2 sm:w-full mx-auto">
    <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
      <div class="mt-8 mx-auto max-w-7xl px-4 sm:mt-4 sm:px-6">
        <div class="text-center">
          <h1
            class="
              text-2xl
              tracking-tight
              font-extrabold
              text-gray-900
              sm:text-2xl
              md:text-2xl
            "
          >
            <span class="block text-mygrad">Let's get started</span>
          </h1>
        </div>
      </div>
    </div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        class="space-y-4 text-gray-700"
        @submit.prevent="handleSubmit(submit)"
      >
        <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
          <div class="w-full px-2">
            <ValidationProvider
              rules="required|min:3"
              v-slot="{ errors, valid }"
            >
              <label class="block mb-1" for="formGridCode_name"
                >Full name</label
              >
              <input
                class="
                  w-full
                  h-10
                  px-3
                  text-base
                  placeholder-gray-600
                  border
                  rounded-lg
                  focus:shadow-outline
                "
                type="text"
                id="formGridCode_name"
                name="name"
                v-model="form.name"
                placeholder="Your Name to be shown at graduation"
                v-bind:class="{
                  'border-red-700 text-red-900': errors.length > 0,
                  'border-green-700': valid,
                }"
              />
            </ValidationProvider>
          </div>
        </div>
        <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
          <div class="w-full px-2">
            <ValidationProvider
              name="Email Address"
              rules="required|email|personal_email"
              v-slot="{ valid, errors }"
              vid="email"
            >
              <label class="block mb-1" for="formGridCode_email"
                >Email Address</label
              >
              <input
                ref="email"
                class="
                  w-full
                  h-10
                  px-3
                  text-base
                  placeholder-gray-600
                  border
                  rounded-lg
                  focus:shadow-outline
                "
                type="email"
                id="formGridCode_email"
                name="email"
                v-model="form.email"
                placeholder="john.doe@example.com"
                v-bind:class="{
                  'border-red-700 text-red-900': errors.length > 0,
                  'border-green-700': valid,
                }"
              /><span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
          <div class="w-full px-2">
            <ValidationProvider
              name="Confirmation Email Address"
              rules="required|confirmed:email"
              v-slot="{ valid, errors }"
            >
              <label class="block mb-1" for="formGridCode_email_conf"
                >Confirm Email Address</label
              >
              <input
                class="
                  w-full
                  h-10
                  px-3
                  text-base
                  placeholder-gray-600
                  border
                  rounded-lg
                  focus:shadow-outline
                "
                type="email"
                id="formGridCode_email_conf"
                name="confirmed_email"
                v-model="form.confirmEmail"
                placeholder="john.doe@example.com"
                v-bind:class="{
                  'border-red-700 text-red-900': errors.length > 0,
                  'border-green-700': valid,
                }"
              /><span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <!-- TODO ADD dates and list faculties -->
        <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
          <div class="w-full px-2">
            <label class="block mb-1" for="formGridCode_event">Event</label>
            <select
              v-model="form.event"
              class="
                w-full
                h-10
                px-3
                text-base
                placeholder-gray-600
                border
                rounded-lg
                focus:shadow-outline
              "
              id="formGridCode_event"
              name="event"
            >
              <option disabled :value="null">Select your event date</option>

              <option
                v-for="ceremony in events"
                :value="ceremony['id']"
                :key="ceremony['id']"
              >
                {{ ceremony["datetime"] | formatDate }}
              </option>
            </select>
          </div>
        </div>

        <button
          type="submit"
          class="
            inline-flex
            items-center
            px-4
            py-2
            border border-gray-300
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
        >
          Go to Checkout
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { extend } from "vee-validate";
import {
  required,
  min,
  max,
  email,
  regex,
  confirmed,
} from "vee-validate/dist/rules";

extend("personal_email", {
  message: (field) =>
    "The " +
    field +
    " value is not a personal email, please enter an email not ending in ac.uk.",
  validate: (value) => !/^.*(\.ac\.uk)$/.test(value),
});

extend("required", {
  ...required,
  message: "This field is required",
});
extend("min", min);
extend("confirmed", {
  ...confirmed,
  message: "Your email addresses do not match",
});
extend("regex", regex);
extend("max", max);
extend("email", email);
export default {
  name: "DigitalDownloadStep1",
  props: { events: Array },
  data: function () {
    return {
      form: {
        name: "",
        event: null,
      },
    };
  },
  created: function () {
  },
  methods: {
    submit() {
      console.log(this.form);
      this.$emit("submit", this.form);
    },
  },
};
</script>
