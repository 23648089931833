<template>
  <div>
    <loading
      :name="firstname(registration['name'])"
      v-if="this.state == 'loading'"
    />
    <find-initial-focal-point
      :firstname="firstname(registration['name'])"
      :ceremony="this.registration.ceremony"
      v-if="this.state == 'initial'"
      @submit="submitFocalPoint"
    />
    <audio-notice :name="firstname(registration['name'])"
      v-if="this.state == 'audio'"
      @submit="state='fineTune'" />
    <manual-submission-page
      v-if="this.state == 'fineTune'"
      :focalPoint="focalPoint"
      :ceremony="this.registration.ceremony"
      @submit="submitFineTune"
      :errors="this.errors"
    /> 
    <submitted
      v-if="this.state == 'submitted'"
      :name="firstname(registration['name'])"
    />
  </div>
</template>

<script>
import FindInitialFocalPoint from "./FindInitialFocalPoint.vue";
import ManualSubmissionPage from "./ManualSubmissionPage.vue";
import axios from "axios";
import Submitted from "./Submitted.vue";
import Loading from "../Loading.vue";
import AudioNotice from './AudioNotice.vue';
import {endSession, trackEvent} from "../../Analytics.vue"

export default {
  name: "FindStudentProcess",
  props: {
    organiser: Object
  },
  data: function () {
    return {
      state: "loading",
      min: null,
      max: null,
      focalPoint: 0,
      registration: {},
      errors: [],
      audio : ""
    };
  },
  computed: {},
  methods: {
    firstname(name) {
      return name.split(" ")[0];
    },
    submitFocalPoint(data) {
      this.focalPoint = data;
      axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
      axios
          .get(`/universities/${this.organiser.id}/ceremonies/${this.registration.ceremony.id}/audio`)
          .then((audio_response) => audio_response.data)
          .then((response_data) => {
            this.audio = response_data.audio;
            this.state = "audio";
          });    
    },
    submitFineTune(data) {
      this.min = data.min;
      this.max = data.max;
      trackEvent(this.$route.path, {label: "MANUAL_SUBMISSION_END", registration_id: this.$route.params.id})
      axios
        .post(`/registrations/${this.$route.params.id}/submissions`, {
          in: this.min,
          out: this.max,
          include_audio: data.include_audio
        })
        .then((response) => {
          trackEvent(this.$route.path, {label: "MANUAL_SUBMISSION_SUCCESS", registration_id: this.$route.params.id, submission_id: response.data["id"]})
          this.state = "submitted";
          console.log(response.data["id"]);
          endSession();
        })
        .catch((error) => {
          trackEvent(this.$route.path, {label: "MANUAL_SUBMISSION_FAIL", registration_id: this.$route.params.id, error:error.response.data["error"]})
          this.errors = [error.response.data["error"]];
        });
    },
  },
  beforeCreate() {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    axios
      .get(`/registrations/${this.$route.params.id}`)
      .then((response) => response.data)
      .then((response_data) => {
        this.registration = response_data;
        axios
          .get(`/ceremonies/${this.registration.ceremony}`)
          .then((ceremony_response) => ceremony_response.data)
          .then((response_data) => {
            this.registration.ceremony = response_data;
            this.state = "initial";
          });
 
      });
      trackEvent(this.$route.path, {label: "MANUAL_SUBMISSION_START", registration_id: this.$route.params.id})
  },

  components: {
    FindInitialFocalPoint,
    ManualSubmissionPage,
    Submitted,
    Loading,
    AudioNotice,
    
  },
};
</script>
<style scoped>
video {
  min-width: 100% !important;
  min-height: 40em;
  position: relative !important;
}
.video-js {
  width: 100% !important;
  position: relative !important;
}
</style>