import { render, staticRenderFns } from "./FindStudentProcess.vue?vue&type=template&id=39361f6e&scoped=true"
import script from "./FindStudentProcess.vue?vue&type=script&lang=js"
export * from "./FindStudentProcess.vue?vue&type=script&lang=js"
import style0 from "./FindStudentProcess.vue?vue&type=style&index=0&id=39361f6e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39361f6e",
  null
  
)

export default component.exports