<template>
  <div id="app">
    <div class="mx-auto md:w-1/2 sm:w-full justify-center">
      <img class="mx-auto" src="./assets/mgclogo.png" width="50%" />
    </div>
    <loading v-if="!ready" />
    <router-view
      v-if="ready"
      :organiser="organiser"
      :events="this.events"
      :additionalFieldsInput="additionalFields"
    ></router-view>
    <!--
    <register-form
      msg="Sign up for your GraduationClip"
      class="mx-auto"
      :organiser="organiser"
      :events="events"
    />-->
  </div>
</template>

<script>
import Loading from "./components/Loading.vue";
import Auth from "./api/auth";
import axios from "axios";
import { startSession, endSession, iOS } from './Analytics.vue';

// listen for all the exit events
window.addEventListener('pagehide', endSession)
window.addEventListener('beforeunload', endSession)
window.addEventListener('unload', endSession)

// for iOS when the focus leaves the tab
if (iOS()) window.addEventListener('blur', endSession)

export default {
  name: "App",
  components: { Loading  },
  data: function () {
    return {
      ready: false,
      organiser: null,
      events: null,
      signedIn: false,
      additionalFields: [],
    };
  },
  methods: {

  },
  beforeCreate() {
    console.log(this.$route.path)
    const token = localStorage.getItem("token")
    if (token) {
       axios.defaults.headers.common["Authorization"] =
            "Bearer " + token;
    }
    if (this.$route.matched.some(({ path }) => path.includes("admin") || path.includes("privacy-policy"))) {
      console.log(this.ready)
    } else {
      var sub = this.$route.query.uni;
      if (
        typeof sub == "undefined" &&
        typeof this.$route.params.uni != "undefined"
      ) {
        sub = this.$route.params.uni;
      }
      axios
        .get(`/universities/slug/${sub}`)
        .then((response) => response.data)
        .then(
          (response_data) => (
            (this.organiser = response_data),
            (this.events = response_data["ceremonies"]),
            (this.additionalFields = response_data["additionalFields"]),
            Auth.startSessionForTenant(response_data["tenant"]).then(() => {
              this.ready = true;
            }),
            startSession(response_data["tenant"], this.$route, {universitySlug: sub, ref: document.referrer, userAgent: navigator.userAgent, "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone})
          ) 
        );
    }
    // Once we have a university then get a JWT
  },
  created() {
    if (this.$route.matched.some(({ path }) => path.includes("admin")  || path.includes("privacy-policy"))) {
        this.ready = true;
        }
  },
};
</script>

<style>
body {
  margin: 1rem;
  padding: 1rem;
  background-color: rgb(217, 246, 255);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.logo {
  max-width: 100%;
}
.text-mygrad {
  color: #08697b;
}
.bg-mygrad {
  background-color: #08697b;
}
.bg-mygrad-light {
  background-color: #05b1d2 !important;
}
.text-mygrad-light {
  color: #05b1d2;
}
</style>
