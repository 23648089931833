<template>
  <div class="md:w-1/2 sm:w-full mx-auto">
    <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
      <div class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
        <div class="text-center">
          <h1
            class="
              text-2xl
              tracking-tight
              font-extrabold
              text-gray-900
              sm:text-3xl
              md:text-4xl
            "
          >
            <span class="block" v-if="name"
              >{{ name }}, when choosing your time please ignore the
              audio.</span
            ><br/>
            <span class="block text-mygrad"
              >Your sound will be heard under your name, which is displayed
              graphically. Don’t choose the audio, just choose the moment you
              start to walk.</span
            >
          </h1>
        </div>
      </div>
      <div class="mt-8 mx-auto py-3 max-w-7xl w-full">
        <div class="text-center w-full h-auto">
          <button
            @click="submit"
            type="submit"
            class="
              inline-flex
              items-center
              px-4
              py-2
              border border-gray-300
              shadow-sm
              text-lg
              font-medium
              rounded-md
              text-gray-700
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AudioNotice",
  props: { name: String },
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
};
</script>