<template>
  <div>
    <AudioToolLoading v-if="this.state == 'loading'" />
    <audio-submission
      v-if="this.state == 'ready'"
      :audio="this.audio"
      :focalPoint="this.focalPoint"
      :ceremony="this.ceremony"
      :errors="this.errors"
      @submit="submitAudioOverride"
      :peaks="this.peaks"
    />
    <submitted
      v-if="this.state == 'submitted'"
      :name="firstname(this.clip.registration['name'])"
    />
  </div>
</template>
  
  <script>
import axios from "axios";
import Submitted from "../Submitted.vue";
import AudioToolLoading from "./AudioToolLoading.vue";
import AudioSubmission from "./AudioSubmission.vue";

export default {
  name: "AudioTool",
  props: {
    organiser: Object,
  },
  data: function () {
    return {
      state: "loading",
      min: null,
      max: null,
      focalPoint: 20,
      ceremony: {},
      errors: [],
      audio: "",
      peaks: null,
      clip: {
        registration: { ceremony: "" },
      },
    };
  },
  computed: {},
  methods: {
    firstname(name) {
      return name.split(" ")[0];
    },
    setData(d) {
      this.clip = d;
      this.focalPoint = d.submission.in_point;
      return d;
    },
    submitAudioOverride(data) {
      this.min = data.min;
      this.max = data.max;
      axios
        .post(`/clips/${this.$route.params.id}/audio`, {
          audio_in: this.min,
          audio_out: this.max,
        })
        .then((response) => {
          this.state = "submitted";
          console.log(response.data);
        })
        .catch((error) => {
          this.errors = [error.response.data["error"]];
        });
    },
  },
  beforeCreate() {
    const clipId = this.$route.params.id;
    axios
      .get(`/clips/${clipId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => response.data)
      .then((response_data) => this.setData(response_data))
      .then((clipData) =>
        axios
          .get(`/ceremonies/${clipData.registration.ceremony}`)
          .then((ceremony_response) => ceremony_response.data)
          .then((response_data) => {
            this.ceremony = response_data;
            axios
              .get(
                `/universities/${this.organiser.id}/ceremonies/${this.ceremony.id}/audio`
              )
              .then((audio_response) => audio_response.data)
              .then((response_data) => {
                this.audio = response_data.audio;
                this.state = "ready";
                this.peaks = response_data.waveform;
              });
          })
      )
      .catch((error) => (this.error = error.response.error));

    // Get Submission
  },

  components: {
    Submitted,
    AudioToolLoading,
    AudioSubmission,
  },
};
</script>
  <style>
video {
  min-width: 100%;
  position: relative !important;
}
</style>