<template>
  <div class="">
    <automatic-submission-accepted v-if="automatic_accepted" />
    <loading v-else-if="automatic_loading"  :name="name" />
    <div v-else>
    <div  class="flex flex-wrap -mx-2 space-y-4 md:space-y-0" >
      <div class="mt-8 mx-auto max-w-7xl">
        <div class="text-center w-full h-auto">
          <h1
            class="
              text-4xl
              tracking-tight
              font-extrabold
              text-gray-900
              sm:text-5xl
              md:text-6xl
            "
          >
            <span class="block">Are you shown</span>
            <span class="block text-mygrad">in these thumbnails?</span>
          </h1>
        </div>
      </div>
    </div>
    <div class="mt-4 mx-auto py-2 max-w-7xl w-full" v-if="thumbnails.length">
      <div class="text-center">
        <button
          @click="notFoundButtonClick"
          type="submit"
          class="
            inline-flex
            items-center
            px-4
            py-2
            border border-gray-300
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:opacity-50!important
          "
        >
          I cannot see myself
        </button>
      </div>
    </div>
    <div
      class="max-w-2xl mx-auto py-4 px-4 sm:py-4 sm:px-6 lg:max-w-7xl lg:px-8"
    >
      <div
        class="
          grid grid-cols-1
          gap-y-10
          sm:grid-cols-2
          gap-x-6
          lg:grid-cols-3
          xl:gap-x-8
        "
      >
        <single-thumbnail
          v-for="thumbnail in thumbnails"
          :key="thumbnail.hash"
          :confidence="thumbnail.confidence"
          :src="thumbnail.thumbnail"
          :gifSrc="thumbnail.gif"
          :url="thumbnail.url"
          :hash="thumbnail.hash"
          @clickThumbnail="clickThumbnail($event)"
        />
      </div>
    </div>
    <div class="mt-4 mx-auto py-2 max-w-7xl w-full" v-if="thumbnails.length">
      <div class="text-center">
        <button
          @click="notFoundButtonClick"
          type="submit"
          class="
            inline-flex
            items-center
            px-4
            py-2
            border border-gray-300
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:opacity-50!important
          "
        >
          I cannot see myself
        </button>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import AutomaticSubmissionAccepted from "../AutomaticSubmissionAccepted.vue";
import SingleThumbnail from "./SingleThumbnail.vue";
import axios from "axios";
import Loading from '../Loading.vue';
import {trackEvent} from "../../Analytics.vue"
export default {
  components: { SingleThumbnail, AutomaticSubmissionAccepted, Loading },
  name: "Thumbnails",
  beforeCreate() {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    axios
      .get(`/registrations/${this.$route.params.id}/thumbnails`)
      .then((response) => response.data)
      .then((response_data) => {
        this.thumbnails = response_data.predictions;
        this.name = response_data.registration.name;
        this.default_url = {path: `/submission/${response_data.registration.id}`}
        this.registration = response_data.registation;
      })
      .catch((error) => {
        console.log(error.response)
        window.location.replace(error.response.data.url)
      });
  },
  methods: {
    notFoundButtonClick() {
      trackEvent(this.$route.path, {label: "THUMBNAIL_NOT_FOUND_CLICK", registration_id: this.$route.params.id})
      this.$router.push(this.default_url);
    },
    clickThumbnail(hash_string) {
      var reg_id = this.$route.params.id;
      this.automatic_loading = true;
      trackEvent(this.$route.path, {label: "THUMBNAIL_CLICK", registration_id: reg_id, hash_string: hash_string})
      axios
      .post(`/registrations/${reg_id}/submissions/automatic`, {phrase: hash_string})
      .then((response) => (response.data, (this.automatic_accepted = true, this.automatic_loading=false), trackEvent(this.$route.path, {label: "THUMBNAIL_CLICK_SUCCESS", registration_id: reg_id, hash_string: hash_string})))
      .catch((error) => {
        console.log(error.response);
        trackEvent(this.$route.path, {label: "THUMBNAIL_CLICK_FAILED", registration_id: reg_id, hash_string: hash_string})
        window.location.replace(error.response.data.url);
      });
    }
  },
  data: function () {
    return {
      thumbnails: [],
      name: "",
      default_url: "",
      registration: {},
      automatic_accepted: false,
      automatic_loading: false
    };
  },
};
</script>