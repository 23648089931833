<template>
    <div>
      <LegacySnapshotDetailsForm
        v-if="state == 'one'"
        :events="events"
        :name="registration.name"
        :ceremony_id="registration.ceremony_id"
        @submit="submitStepOne"
      />
      <Selector
        v-if="state == 'selector'"
        :name="registration.name"
        :ceremony="registration.ceremony"
        @submit="submitImageSelection"
        :errors="[]"
        :startPoint="startPoint"
        :disableSubmit="disableSubmit"
      />
      <CeremonyNotReady v-if="state == 'error'" />
    </div>
  </template>
    
    <script>
    import Selector from "./Selector.vue"
  // Import stylesheet
  import axios from "axios";
  import "vue-loading-overlay/dist/vue-loading.css";
  import LegacySnapshotDetailsForm from "./LegacySnapshotDetailsForm.vue";

  import CeremonyNotReady from './../CeremonyNotReady.vue';
  export default {
    name: "LegacySnapshot",
    props: {
      organiser: Object,
      additionalFieldsInput: Array,
    },
    components: {
        Selector,
      LegacySnapshotDetailsForm,
      CeremonyNotReady
    },
    data: function () {
      return {
        state: "gallery",
        registration: {},
        startPoint: 0,
        isLoading: false,
        events: [],
        registrationID: "",
        disableSubmit: false,
      };
    },
    mounted() {
      this.events = this.organiser["ceremonies"];
    },
    created: function () {
      var sub = this.$route.query.registration;
      if (typeof sub == "undefined") {
        this.state = "one";
        return;
      }
      var startPoint = this.$route.query.startPoint;
      if (typeof startPoint !== "undefined") {
        this.startPoint = parseFloat(startPoint)
      }
      axios
        .get(`/registrations/${this.$route.query.registration}`)
        .then((r) => {
          this.registrationID = this.$route.query.registration;
          this.registration.name = r.data.name;
          this.registration.ceremony_id = r.data.ceremony;
          this.registration.email = r.data.email || "";
          if (this.registration.email == "") {
            this.state = "one";
            console.log("no email");
            throw "no email";
          }
          this.populated = true;
        }).then(() => {
          axios
          .get(`/ceremonies/${this.registration.ceremony_id}`)
          .then((ceremony_response) => ceremony_response.data)
          .then((response_data) => {
            this.registration.ceremony = response_data;
            this.state = "selector";
          })})
        .catch(() => {
          this.state = "one";
        })
    },
    methods: {
      firstname(name) {
        return name.split(" ")[0];
      },
      submitStepOne(formData) {
        this.registration = formData;
        console.log(this.registration)
        axios
          .get(`/ceremonies/${this.registration.ceremony_id}`)
          .then((ceremony_response) => ceremony_response.data)
          .then((response_data) => {
            this.registration.ceremony = response_data;
            this.state = "selector";
          }) 
      },
      submitImageSelection(data) {
        console.log(data);
        this.disableSubmit = true;
        axios
          .post(
            `${process.env.VUE_APP_LEGACY_SNAPSHOT_API_BASE}/order`,
            {
              name: this.registration.name,
              email: this.registration.email,
              ceremony: this.registration.ceremony_id,
              slug: this.$route.params.uni,
              tenant: "mygraduationfilm",
              frame: data.frame,
            }
          )
          .then((r) => {
            window.location.href = r.data;
          });
      
      },
    },
  };
  </script>
    