<template>
  <div>
    <DigitalDownloadStep1
      v-if="state == 'one'"
      :events="events"
      @submit="submitStepOne"
    />
    <finished
      v-if="state == 'redirecting'"
      :name="firstname(personalDetails.name)"
      :url="url"
    />
  </div>
</template>

<script>
import Finished from "../Finished.vue";
import axios from 'axios';
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import {endSession, trackEvent} from "../../../Analytics.vue";
import DigitalDownloadStep1 from "./DigitalDownloadStep1.vue";
export default {
  name: "DigitalDownloadForm",
  props: {
    organiser: Object,
  },
  components: {
    DigitalDownloadStep1,
    Finished,
  },
  data: function () {
    return {
      state: "one",
      personalDetails: {},
      id: null,
      isLoading: false,
      events: [],
      url: ""
    };
  },
  mounted() {
    this.events = this.organiser["ceremonies"];
  },
  methods: {
    firstname(name) {
      return name.split(" ")[0];
    },
    submitStepOne(formData) {
      this.personalDetails = formData;
      this.personalDetails.organisation = this.organiser.name;
      console.log(formData);
      this.state = "redirecting";
      
      axios({"method": "POST",
        "baseURL":  process.env.VUE_APP_DIGITAL_DOWNLOAD_BASE_URL,
        "url": "/purchase",
          "data": {
            name: formData["name"],
            email: formData["email"],
            ceremony: formData["event"]
          }
        })
        .then((r) => {
          this.url = r.data;
          trackEvent(this.$route.path, {label: "DIGITAL_DOWNLOAD_PURCHASE_FORM_SUBMITTED", paid: false})
          endSession();
          window.location.href = r.data;
        });
    },
    submit() {
      return true;
    },
  },
};
</script>
