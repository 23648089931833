<script>
export function iOS() {
    try {
        return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.userAgentData.platform)  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    } catch {
        return navigator.userAgent.includes("iPad") || navigator.userAgent.includes("iPhone") || navigator.userAgent.includes("iPod") || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
  
  // iPad on iOS 13 detection
 
}


let skip
let SESSION_DATA = {
    events: [],
    tenant: ""
}

export const trackEvent = (name, properties) => {
    SESSION_DATA.events.push({
        event: name,
        timestamp: new Date().getTime(),
        ...properties
    })
    console.log({
        event: name,
        timestamp: new Date().getTime(),
        ...properties
    })
}

export const startSession = (tenant, router, properties = {}) => {
    console.log(router)
    trackEvent(router.path, {
        label : "PAGE",
    })
    console.log(properties)
    SESSION_DATA.tenant = tenant
    SESSION_DATA = {...SESSION_DATA, ...properties}
}

// call this function on exit
export const endSession = () => {
  // skip if the function has already been called
  if (skip) return
  skip = true
  trackEvent("end session", {label: "END"})
  // I also add an "end session" event to the data here
  const data = SESSION_DATA
  const url = process.env.VUE_APP_ANALYTICS_BASE_URL + "metrics"
  const { vendor } = window.navigator

  // https://bugs.webkit.org/show_bug.cgi?id=188329
  // Safari bug is fixed but not yet released. When that happens, will need to check safari version also
  if (window.navigator.sendBeacon && !~vendor.indexOf('Apple')) {
    // try to send the beacon
    const beacon = window.navigator.sendBeacon(url, JSON.stringify(data))
    if (beacon) return
    // if it failed to queue, (some adblockers will block all beacons), then try the other way
  }

  // Instead, send an async request
  // Except for iOS :(
  const async = !iOS()
  const request = new XMLHttpRequest()
  request.open('POST', url, async) // 'false' makes the request synchronous
  request.setRequestHeader('Content-Type', 'application/json')
  request.send(JSON.stringify(data))

  // Synchronous request cause a slight delay in UX as the browser waits for the response
  // I've found it more performant to do an async call and use the following hack to keep the loop open while waiting

  // Chrome doesn't care about waiting
  if (!async || ~vendor.indexOf('Google')) return

  // Latency calculated from navigator.performance
  const latency = data.latency || 0
  const t = Date.now() + Math.max(300, latency + 200)
  while (Date.now() < t) {
    // postpone the JS loop for 300ms so that the request can complete
    // a hack necessary for Firefox and Safari refresh / back button
  }
}

export default {
  name: "Analytics",
  components: {},
};
</script>