<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "Admin",
};
</script>

<style>
body {
  margin: 1rem;
  padding: 1rem;
  background-color: rgb(217, 246, 255);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.logo {
  max-width: 100%;
}
.text-mygrad {
  color: #08697b;
}
.bg-mygrad {
  background-color: #08697b;
}
.bg-mygrad-light {
  background-color: #05b1d2 !important;
}
.text-mygrad-light {
  color: #05b1d2;
}
</style>