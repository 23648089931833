<template>
    <div class="md:w-1/2 sm:w-full mx-auto">
      <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
        <div class="mt-8 mx-auto max-w-7xl">
          <div class="text-center w-full h-auto">
            <h1
              class="
                text-4xl
                tracking-tight
                font-extrabold
                text-gray-900
                sm:text-5xl
                md:text-6xl
              "
            >
              <span class="block">Now to perfect your</span>
              <span class="block text-mygrad">GraduationSnapshot</span>
            </h1>
            <p
              class="
                mt-3
                max-w-md
                mx-auto
                text-base text-gray-500
                sm:text-lg
                md:mt-5 md:text-xl md:max-w-3xl
              "
            >
              You can use the arrows below the video to move back and forth to find the perfect snapshot.
            </p>
            <p
              class="
                mt-3
                max-w-md
                mx-auto
                text-base text-gray-500
                sm:text-lg
                md:mt-5 md:text-xl md:max-w-3xl
              "
            >
              You can use the double arrows to move at 5 frames per click.
              Please note the image below is heavily compressed and the final image will be of higher quality.
            </p>
          </div>
        </div>
        <div v-if="this.errors.length" class="mt-8 mx-auto max-w-7xl">
          <div class="text-center w-full h-auto">
            <p
              class="
                mt-3
                max-w-md
                mx-auto
                text-base text-red-500
                sm:text-lg
                md:mt-5 md:text-xl md:max-w-3xl
              "
            >
              {{ this.errors[0] }}
            </p>
          </div>
        </div>
        <div class="mt-8 mx-auto max-w-7xl">
          <div class="text-center w-full h-auto">
            <video-player
              :options="videoOptions"
              ref="videoPlayer"
              @statechanged="playerStateChanged"
              @loadeddata="onPlayerReady"
            ></video-player>
            <div class="flex justify-center mt-4 w-full">
              <button
                @click="moveBackFiveFrames"
                class="flex-grow px-4 py-2 mr-2 text-lg font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                &laquo;
              </button>
              <button
                @click="moveBackOneFrame"
                class="flex-grow px-4 py-2 mr-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                &lt;
              </button>

              <button
                @click="moveForwardOneFrame"
                class="flex-grow px-4 py-2 mr-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                &gt;
              </button>
              <button
                @click="moveForwardFiveFrames"
                class="flex-grow px-4 py-2 text-lg font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                &raquo;
              </button>
            </div>

        </div>
        </div>
        
        <div class="text-center w-full justify-center h-auto">
          <button
            @click="submit"
            type="submit"
            class="
              w-full
              mt-8
              px-4
              py-2
              border border-gray-300
              shadow-sm
              text-sm
              font-medium
              rounded-md
              text-gray-700
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
              disabled:opacity-50!important
              disabled:hidden!important
            "
            :disabled="disableSubmit"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import 'videojs-contrib-hls'
  import 'video.js/dist/video-js.css'
  
  export default {
    name: "Selector",
    props: {
      ceremony: Object,
      errors: Array,
      startPoint: Number,
      disableSubmit: Boolean,
    },
    data: function () {
      return {
        video: this.ceremony.video,
        videoOptions: {
          autoplay: false,
          preload: "auto",
          playToggle: false,
          controlBar: {
            playToggle: true,
            currentTimeDisplay: true,
            durationDisplay: true,
            timeControlDisplay: true,
            remainingTimeDisplay: true,
            progressControl: {
              seekBar: true,
            },
            fullscreenToggle: false,
            muteToggle: false,
            volumeBar: false,
            playbackRateMenuButton: false,
          },
          sources: [{
            type: "application/x-mpegURL",
            src: this.ceremony.video,
          }],
          aspectRation: "16:9",
          responsive: true,
        },
        playerReady: false,
      };
    },
    computed: {
      player() {
        return this.$refs.videoPlayer.player;
      },
    },
    methods: {
      onPlayerReady(player) {
        console.log("Pkayer Ready");
        this.playerReady = true;
        this.player.pause();
        player.currentTime(this.startPoint);
      },
      onPlayerPlay(player) {
        console.log("player play!", player);
      },
      onPlayerPause(player) {
        console.log("player pause!", player);
        console.log("Current time: ", player.currentTime());
      },
      playerStateChanged(event) {
        console.log("change", event);
        if ("loadeddata" in event && event.loadeddata) {
          this.onPlayerReady(this.player);
        }
        if ("pause" in event && event.pause) {
          this.onPlayerPause(this.player);
        } else if ("play" in event && event.play) {
          this.onPlayerPlay(this.player);
        }
        console.log("change", event);
      },
    moveBackOneFrame() {
        if (this.playerReady) {
            const currentTime = this.player.currentTime();
            this.player.currentTime(currentTime - (1./25)); // NOTE: THIS IS SET FOR 25FPS
        }
    },
    moveBackFiveFrames() {
        if (this.playerReady) {
            const currentTime = this.player.currentTime();
            this.player.currentTime(currentTime - (5./25));
        }
    },
    moveForwardOneFrame() {
        if (this.playerReady) {
            const currentTime = this.player.currentTime();
            this.player.currentTime(currentTime + (1./25));
        }
    },
    moveForwardFiveFrames() {
        if (this.playerReady) {
            const currentTime = this.player.currentTime();
            this.player.currentTime(currentTime + (5./25));
        }
    },
      submit() {
        this.$emit("submit", {
          frame: this.player.currentTime(),
        });
      },
    },
    components: {
    },
  };
  </script>
  <style>
  video {
    min-width: 100%;
    position: relative !important;
  }
  
  .video-js .vjs-time-divider {
    display: none !important;
  }
  button:disabled {
    visibility: hidden;
  }
  .video-js {
    width: 100% !important;
    height:auto;
    position: relative !important;
  }
  </style>