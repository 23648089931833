<template>
    <div class="md:w-1/2 sm:w-full mx-auto">
      <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
        <div class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
          <div class="text-center">
            <h1
              class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl"
            >
              <span class="block" v-if="name">{{ name }}, we are getting ready.</span>
              <span class="block" v-else>We are working hard in the background to get your audio preview ready.</span>
              <span class="block text-mygrad">This may take up to 30 seconds - hold on tight (we want your GraduationClip to be perfect).</span>
            </h1>
      
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Loading",
    props: { name: String }
  };
  </script>